import React from 'react';
import Search from 'components/properties/Search';
import PropertyList from 'components/properties/PropertyList';
import useStyles from 'styles/pages/ViewListings';

const ViewListingsPage = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Search />
			<PropertyList sellPage={false} />
		</div>
	);
};

export default ViewListingsPage;
